<template>
  <v-container fluid>
    <router-view></router-view>
  </v-container>
</template>
<script>
export default {
  name: 'Guest',
}
</script>
